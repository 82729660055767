<mat-form-field color="primary" style='width: 100%' appearance="outline">
  <mat-label>{{label}}</mat-label>
  
  <input type="time"
         min="00:00"
         max="23:59"
         matInput
         [placeholder]="'Введите значение'"
         [formControl]="formControl">

  <mat-icon matPrefix *ngIf="formControl.value" (click)="onClear()">close</mat-icon>
</mat-form-field>