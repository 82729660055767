export const GEAR_TYPE = [
  {
    id: 'rear_drive',
    title: 'Задний',
  },
  {
    id: 'front_wheel',
    title: 'Передний',
  },
  {
    id: 'four_wheel',
    title: 'Полный',
  },
  {
    id: 'noValue',
    title: 'Нет данных',
  },
];

export const ENGINE_TYPE = [
  {
    id: 'fuel',
    title: 'Бензиновый',
  },
  {
    id: 'diesel',
    title: 'Дизельный',
  },
  {
    id: 'hybrid',
    title: 'Гибридный',
  },
  {
    id: 'turbo_diesel',
    title: 'Турбодизель',
  },
  {
    id: 'turbocharged',
    title: 'Турбированный',
  },
  {
    id: 'compressor',
    title: 'Компрессор',
  },
  {
    id: 'noValue',
    title: 'Нет данных',
  },
];

export const AUCTION_CATEGORY = [
  {
    id: 'CATEGORY_AUTO',
    title: 'Автотраспортное средство',
  },
  {
    id: 'CATEGORY_SPARE',
    title: 'Автозапчасти',
  },
  {
    id: 'CATEGORY_SPECIAL',
    title: 'Спецтехника',
  },
  {
    id: 'CATEGORY_OTHER',
    title: 'Иное имущество',
  },
];

export const CAR_CATEGORY = [{ id: 1, title: "Легковые" }];


export const TRANSMISSION_TYPE = [
  {
    id: 'AUTOMATIC',
    title: 'Автомат',
  },
  {
    id: 'MECHANICAL',
    title: 'Механика',
  },
  {
    id: 'ROBOT',
    title: 'Робот',
  },
  {
    id: 'VARIATOR',
    title: 'Вариатор',
  },
  {
    id: 'NOVALUE',
    title: 'Нет данных',
  },
];

export const STEERING_WHEEL_TYPE = [
  {
    id: 'right',
    title: 'Правый',
  },
  {
    id: 'left',
    title: 'Левый',
  },
  {
    id: 'noValue',
    title: 'Нет данных',
  },
];

export const PTS_TYPE = [
  {
    id: 'mark_car_accident',
    title: 'Возможно отметка о ДТП',
  },
  {
    id: 'present',
    title: 'Есть',
  },
  {
    id: 'not_present',
    title: 'Нет',
  },
  {
    id: 'utilized',
    title: 'Утилизирован',
  },
  {
    id: 'with_inserts',
    title: 'Со вклейками',
  },
  {
    id: 'duplicate',
    title: 'Дубликат',
  },
  {
    id: null,
    title: 'Нет информации',
  },
];


export const INSURANCE_CATEGORIES = [
  {
    id: 'casco',
    title: 'каско',
  },
  {
    id: 'osago',
    title: 'осаго',
  },
  {
    id: 'leasing',
    title: 'лизинг',
  },
  {
    id: 'bankruptcy',
    title: 'по банкротству',
  },
];

export const DAMAGE_LEVEL = [
  { id: 'low', title: 'Низкая' },
  { id: 'medium', title: 'Средняя' },
  { id: 'high', title: 'Высокая' },
];

export const INTERIOR_TYPE = [
  {
    title: 'Ткань',
    id: 'cloth',
  },
  {
    title: 'Велюр',
    id: 'velour',
  },
  {
    title: 'Кожа',
    id: 'leather',
  },
  {
    title: 'Комбинированный',
    id: 'combined',
  },
];

export const INTERIOR_COLOR = [
  {
    title: 'Темный',
    id: 'dark',
  },
  {
    title: 'Светлый',
    id: 'light',
  },
];

export const EXTERIOR_OPTIONS = [
  { id: 'adaptive_headlights', title: 'Адаптивные фары' },
  { id: 'xenon_headlights', title: 'Ксеноновые фары' },
  { id: 'sunroof', title: 'Люк на крыше' },
  { id: 'glass_tinting', title: 'Тонировка стекла' },
  { id: 'light_plastic_wheels', title: 'Легкопластиковые диски' },
];

export const INTERIOR_OPTIONS = [
  { id: 'interior_type', title: 'Обивка салона' },
  { id: 'interior_color', title: 'Цвет салона' },
];

export const SIGNAL_TYPE = [
  { id: 'remote_engine_start', title: 'Дистанционный запуск двигателя' },
  { id: 'feedback', title: 'Обратная связь' },
  { id: 'immobilizer', title: 'Иммобилайзер' },
  { id: 'established', title: 'Штатная' },
];

export const AIR_BAGS_TYPE = [
  {
    id: 'missing',
    title: 'Отсутствуют',
  },
  {
    id: 'driver',
    title: 'Водителя',
  },
  {
    id: 'driver_and_passenger',
    title: 'Водителя и пассажира',
  },
  {
    id: 'front_and_side',
    title: 'Передние и боковые',
  },
];

export const CLIMATE_CONTROL_TYPE = [
  {
    id: 'missing',
    title: 'Отсутствует',
  },
  {
    id: 'one_zone',
    title: '1 зонный',
  },
  {
    id: 'two_zone',
    title: '2 зонный',
  },
  {
    id: 'three_zone',
    title: '3 зонный',
  },
];

export const WINDOW_TYPE = [
  {
    id: 'manual',
    title: 'Ручные',
  },
  {
    id: 'auto_front',
    title: 'Электродом передние',
  },
  {
    id: 'auto_all',
    title: 'Электродом все',
  },
];

export const SECURITY_TYPE = [
  {
    id: 'abs',
    title: 'Антиблокировочная система ABS',
  },
  {
    id: 'parking_sensors',
    title: 'Парктроник',
  },
  {
    id: 'traction_control_system',
    title: 'Антипробуксовочная система',
  },
  {
    id: 'stability_control_system',
    title: 'Система курсовой устойчивости',
  },
];
export const REGULATIONS_TYPE = [
  {
    id: 'power_window',
    title: 'Стеклоподъемники',
  },
  {
    id: 'driver_seat_ids',
    title: 'Сиденье водителя',
  },
  {
    id: 'is_power_mirrors',
    title: 'Электропривод зеркал',
  },
];
export const MULTIMEDIA_TYPE = [
  {
    id: 'cassette_player',
    title: 'Кассетник',
  },
  {
    id: 'cd',
    title: 'CD',
  },
  {
    id: 'tv',
    title: 'TV',
  },
];

export const FUNCTIONAL_EQUIPMENT_TYPE = [
  {
    id: 'on_board_computer',
    title: 'Бортовой компьютер',
  },
  {
    id: 'rain_sensor',
    title: 'Датчик дождя',
  },
  {
    id: 'cruise_control',
    title: 'Круиз-контроль',
  },
  {
    id: 'heated_mirrors',
    title: 'Обогрев зеркал',
  },
  {
    id: 'air_suspension',
    title: 'Пневмоподвеска',
  },
  {
    id: 'central_locking',
    title: 'Центральный замок',
  },
  {
    id: 'gas_equipment',
    title: 'Газобалонное оборудование',
  },
  {
    id: 'light_sensor',
    title: 'Датчик света',
  },
  {
    id: 'navigation_system',
    title: 'Навигационная система',
  },
  {
    id: 'headlight_washer',
    title: 'Омыватель фар',
  },
  {
    id: 'power_steering',
    title: 'Усилитель руля',
  },
];

export const LOT_STATUSES = [
  { id: 'NEW', name: 'Новый лот' },
  { id: 'PUBLISHED', name: 'Опубликован' },
  { id: 'UNPUBLISHED', name: 'Не опубликован' },
  { id: 'COMPLETED', name: 'Завершен' },
  { id: 'READY_TO_TRANSFER', name: 'Готов к передаче' },
  { id: 'TRANSFERRED', name: 'Передан' },
  { id: 'PAID', name: 'Оплачен' },
  { id: 'NOT_PAID', name: 'Не оплачен' },
  { id: 'REFUSED', name: 'Отказ от лота' },
  { id: 'ARCHIVED', name: 'В архиве' },
];

export const AUCTION_TYPE = [
  { id: 'TYPE_OPENED', title: 'Открытый' },
  { id: 'TYPE_CLOSED', title: 'Закрытый' },
  // { id: '3', title: 'На понижение' },
];

export const ADMIN_USER_TYPE = [
  { id: 'seller', title: 'Покупатель' },
  { id: 'buyer', title: 'Продавец' },
  { id: 'marketing', title: 'Маркетолог' },
  { id: '', title: 'Все' },
];

export const PROFILE_USER_TYPE_FILTER = [
  { id: 'seller', title: 'Продавец' },
  { id: 'buyer', title: 'Покупатель' },
  { id: 'marketing', title: 'Маркетолог' },
  { id: '', title: 'Все' },
];

export const PROFILE_TYPE = [
  { id: 'seller', title: 'Продавец' },
  { id: 'buyer', title: 'Покупатель' },
  { id: 'admin', title: 'Администратор' },
  { id: 'super_admin', title: 'Старший-администратор' },
  { id: 'marketing', title: 'Маркетолог' },
];

export const AIRBAG_DAMAGE_TYPE = [
  { id: 'rear_left_curtain', title: 'Задняя левая шторка' },
  { id: 'driver_knee', title: 'Колено водителя' },
  { id: 'srs_module', title: 'Модуль SRS' },
  { id: 'passenger_door_trim', title: 'Обшивка двери пассажира' },
  { id: 'passenger_belt', title: 'Ремень пассажира' },
  { id: 'driver_seat', title: 'Сиденье водителя' },
  { id: 'torpedo', title: 'Торпедо' },
  { id: 'passenger_curtain', title: 'Шторка пассажира' },
  { id: 'rear_right_curtain', title: 'Задняя правая шторка' },
  { id: 'passenger_knee', title: 'Колено пассажира' },
  { id: 'driver_door_trim', title: 'Обшивка двери водителя' },
  { id: 'driver_belt', title: 'Ремень водителя' },
  { id: 'steering_wheel', title: 'Руль' },
  { id: 'passenger_seat', title: 'Сиденье рассажира' },
  { id: 'driver_blind', title: 'Шторка водителя' },
];

export const ECOM_PAY_STATUS_TYPE = [
  {
    id: 'awaits',
    title: 'Ожидает',
  },
  {
    id: 'success',
    title: 'Успех',
  },
  {
    id: 'decline',
    title: 'Отклонен',
  },
  {
    id: 'processing',
    title: 'В процессе',
  },
  {
    id: 'canceled',
    title: 'Отменен',
  },
  {
    id: 'refunded',
    title: 'Возвращен',
  },
  {
    id: 'expired',
    title: 'Просрочен',
  },
  {
    id: 'error',
    title: 'Ошибка',
  },
];
