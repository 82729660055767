import {Component, Input} from '@angular/core';
import {BaseFormFieldAbstractComponent} from "@models/base-form-field.abstract.component";

@Component({
  selector: 'mig-input-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent extends BaseFormFieldAbstractComponent {
  @Input() label: string = '';
  @Input() formClass: string = '';

  onClear() {
    this.formControl.setValue(null);
  }
}
