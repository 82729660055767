export const API_HANDLERS: Record<string, string> = {
  login: '/api/auth/admin-login',
  user: '/api/user/me',
};

export const LOTS_API_HANDLERS: Record<string, string> = {
  lotsAllTable: '/api/admin/auctions',
  lotSingle: '/api/admin/auctions/',
  approveRefusal: '/api/admin/auctions/',
  transfer: '/api/admin/auctions/bids/',
  deleteBet: '/api/admin/bids/',
  deleteAutoBet: '/api/admin/autobids/',
  updateAutoBet: '/api/admin/autobids/',
  refuse: '/api/admin/auctions/bids/',
};

export const USERS_API_HANDLERS: Record<string, string> = {
  userProfilesList: '/api/admin/users',
  loadSingleUser: '/api/admin/users/',
  updateUser: '/api/admin/users/',
  createUser: '/api/admin/users/',
  deleteUser: '/api/admin/users/',
  loadUsersForPeriod: '/api/admin/users/export',
  loginAsUser: '/api/admin/auth/user/',
};

export const TARIFFS_LIST: Record<string, string> = {
  tariffsList: '/api/admin/tariffs'
}

export const PAYMENTS_API_HANDLERS: Record<string, string> = {
  paymentsList: '/api/admin/payments/',
  deletePayment: '/api/admin/payments/',
};

export const NEWS_API_HANDLERS: Record<string, string> = {
  loadNewsList: '/api/admin/news',
  createNews: '/api/admin/news',
  loadNews: '/api/admin/news/',
  updateNews: '/api/admin/news/',
  deleteNews: '/api/admin/news/',
};

export const ARTICLE_API_HANDLERS: Record<string, string> = {
  loadArticlesList: '/api/admin/news',
  createArticle: '/api/admin/news',
  loadArticle: '/api/admin/news/',
  updateArticle: '/api/admin/news/',
  deleteArticle: '/api/admin/news/',
};

export const REPORTS_API_HANDLERS: Record<string, string> = {
  getDailyReportOfLotsExcel: '/api/admin/auctions/new/export/excel',
  getDailyReportOfLotsHtml: '/api/admin/auctions/new/export/html',
  getReportOfCloseTradingHtml: '/api/admin/auctions/completed/export/html',
  getReportOfPaidServicesHtml: '/api/admin/export/services-paid/html',
  getReportOfCloseTradingExcel: '/api/admin/auctions/completed/export/excel',
  getReportOfPaidServicesExcel: '/api/admin/export/services-paid/excel',
  getLogsOfVinNumbersExcel: '/api/admin/logs/vin-check/excel',
  getLogsOfVinNumbersHtml: '/api/admin/logs/vin-check/html',
}

export const API_DICTIONARIES: Record<string, string> = {
  region: '/api/dictionaries/regions',
  city: '/api/dictionaries/cities',
  carType: '/api/dictionaries/carcasses',
  carBrand: '/api/dictionaries/brands',
  carModel: '/api/dictionaries/models',
  damages: '/api/dictionaries/damages',
  comforts: '/api/dictionaries/comforts',
  seats: '/api/dictionaries/seats',
  driver_seats: '/api/dictionaries/driver_seats',
  transports: '/api/dictionaries/transports',
  colors: '/api/dictionaries/colors',
};

export const API_MEDIA: Record<string, string> = {
  postMedia: '/api/media',
  deleteMedia: '/api/media/',
  updateMedia: '/api/admin/media/',
};
